<div class="container-user-details">
	<div class="search-layout-header">
		<div class="search-layout-breadcrumb">
			<intranet-breadcrumb></intranet-breadcrumb>
		</div>
	</div>
	
	<!-- USER BANNER -->
	<div class="user-banner flex mt-small mb-large mt-large">
		<!-- ICON -->
		<fa-icon [icon]="icons.faUser"></fa-icon>

		<!-- USER INFO -->
		<div class="ml-medium align-self-center">
			<p class="name">{{ currentUser?.lastname }} {{ currentUser?.firstname }}</p>
			<p class="sub-info">{{ currentUser?.email }}</p>
		</div>
	</div>

	<div class="blue-container">
		<!-- HEADER -->
		@if (userAgencyRoles && userAgencyRoles.size > 0) {
			<div class="flex mb-medium">
				<p class="header-label agencies-col">Agence</p>
				<p class="header-label ml-large">Rôles</p>
			</div>
		} @else {
			<p class="empty-txt text-center mt-large">Aucun profil défini pour cet utilisateur</p>
		}

		@if (userAgencyRoles) {
			<div class="agencies-row">
				@for (userAgencyRole of userAgencyRoles | keyvalue; track userAgencyRole.key; let i = $index) {
					<div [ngClass]="{ 'flex agency-row px-medium': true, first: i == 0, last: i == (userAgencyRoles | keyvalue).length - 1 }">
						<fa-icon
							class="align-self-center house"
							[icon]="icons.faHouse"></fa-icon>
						<!-- AGENCY -->
						<ng-select
							[disabled]="userAgencyRole.value.length > 0"
							placeholder="Agence"
							[searchable]="false"
							bindLabel="name"
							(change)="refreshSelectableAgencies({ index: i, selectedAgency: $event })"
							[clearable]="false"
							[(ngModel)]="userAgencyRole.key"
							[items]="selectableAgencies"
							class="align-self-center">
						</ng-select>

						<!-- ROLES -->
						<div class="roles w-full ml-medium">
							@for (role of userAgencyRole.value; track role; let j = $index) {
								<span class="tag dark m-small align-self-center">
									<p>{{ role.name }}</p>
									<!-- DELETE -->
									@if (userAgencyRole.value.length > 1 && !isReadOnly()) {
										<fa-icon
											[icon]="icons.faTimes"
											class="ml-small"
											(click)="removeRole(userAgencyRole.key, userAgencyRole.value[j])"></fa-icon>
									}
								</span>
							}

							<!-- TAG BUTTON -->
							@if (!isReadOnly()) {
								<intranet-dropdown
									[className]="className.medium"
									[options]="getRemainingRoles(userAgencyRole)"
									[align]="'right'"
									(rowIsClicked)="addRole({ agency: userAgencyRole.key, role: $event })"
									class="m-small dropdown-tag align-self-center">
									<span>Ajouter un rôle</span>
									<fa-icon [icon]="icons.faChevronRight"></fa-icon>
								</intranet-dropdown>
							}
						</div>

						<!-- DELETE BUTTON -->
						@if (userAgencyRoles.size > 1 && !isReadOnly()) {
							<fa-icon
								(click)="removeAgencyRole(userAgencyRole)"
								class="remove ml-auto align-self-center hover"
								[icon]="icons.faTrash"></fa-icon>
						}
					</div>
				}

				<!-- </form> -->
			</div>
		}

		@if (selectableAgencies.length > 0 && !isReadOnly()) {
			<div class="flex mt-small">
				<button
					class="btn-sos text-btn hover"
					(click)="addAgencyRoleLine()">
					<p>Ajouter un profil</p>
					<fa-icon [icon]="icons.faCirclePlus"></fa-icon>
				</button>
			</div>
			<hr class="align-self-center" />
		}
	</div>
</div>


<p-confirmDialog></p-confirmDialog>